<script setup>
import MainNotice from "@/components/Ui/MainNotice.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";

defineOptions({
  name: "DropdownNotification",
});

defineProps({
  size: {
    type: String,
    default: "l",
  },

  dropdownOptions: {
    type: Array,
    default: () => [],
  },

  searchOptions: {
    type: Array,
    default: () => [],
  },

  isActive: {
    type: Boolean,
    default: false,
  },

  isShowDeleteNotifications: {
    type: Boolean,
    default: false,
  },

  dropdownType: {
    type: String,
    default: "default",
  },

  scrollContainerId: {
    type: String,
    default: "",
  },
});

const store = useStore();

const emit = defineEmits([
  "controlOptions",
  "readAllNotification",
  "removeAllNotification",
  "hideOptions",
  "hideRemoveNotice",
  "openRemoveNotice",
]);

const isSubmitDeleteDisabled = ref(false);

const notifications = computed(
  () => store.state.notifications?.notificationsList
);

const unreadNotification = computed(
  () => store.state.notifications.unreadNotification
);

const readAllNotification = () => {
  if (unreadNotification.value === 0) {
    store.commit("system/SET_NOTIFICATION", {
      type: "success",
      message: "Все уведомления уже прочитаны",
    });

    hideOptions();
  } else emit("readAllNotification");
};

const hideRemoveNotice = () => {
  emit("hideRemoveNotice");
};

const hideOptions = () => {
  emit("hideOptions");
};

const openRemoveNotice = () => {
  if (notifications.value.length === 0) {
    store.commit("system/SET_NOTIFICATION", {
      type: "success",
      message: "У вас еще нет уведомлений",
    });

    hideOptions();
  } else emit("openRemoveNotice");
};

const removeAllNotification = () => {
  emit("removeAllNotification");
};
</script>

<template>
  <Transition name="dropdown-fade" mode="out-in">
    <div v-if="isActive" class="ui-dropdown">
      <div class="ui-dropdown__options options">
        <button
          class="options__item item"
          type="button"
          @click.stop="readAllNotification"
        >
          <i class="item__icon icon-edit" /> Отметить все как прочитанные
        </button>
        <button
          class="options__item item"
          type="button"
          @click.stop="openRemoveNotice"
        >
          <i class="item__icon icon-trash" /> Очистить все уведомления
        </button>
      </div>
    </div>
  </Transition>
  <MainNotice
    :is-show-notice="isShowDeleteNotifications"
    title="Очистка уведомлений"
    content="Вы действительно ходите удалить все уведомления?"
    submit-text="Удалить"
    :submit-disabled="isSubmitDeleteDisabled"
    @close="hideRemoveNotice"
    @cancel="hideRemoveNotice"
    @submit="removeAllNotification"
  />
</template>

<style lang="scss" scoped>
.ui-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;

  .options {
    border: none;
    width: 288px;
    height: auto;
    background: $background-light;
    box-shadow: 0 4px 16px 0 rgba(5, 10, 43, 0.1);
    border-radius: 12px;
    position: absolute;
    overflow-y: auto;
    padding: 8px 0;
    justify-content: flex-end;
    z-index: 3;
    right: -36px;
    top: 44px;

    .item {
      @include xs-text;
      display: flex;
      text-align: start;
      align-items: center;
      width: 100%;
      color: $text-contrast-primary;
      padding: 12px 12px;
      gap: 8px;
      background: none;
      border: none;
      cursor: pointer;

      &:hover,
      &:focus {
        outline: none;
        width: 100%;
        background: $background-light-accent;
      }

      &__icon {
        width: 20px;
        height: 20px;
        background: $text-contrast-primary;
      }
    }
  }
}

.dropdown-fade-enter-active {
  animation: openSelector 0.1s linear forwards;

  @keyframes openSelector {
    from {
      opacity: 0;
    }

    to {
      opacity: 80%;
    }
  }
}

.dropdown-fade-leave-active {
  animation: closeSelector 0.1s linear forwards;

  @keyframes closeSelector {
    from {
      opacity: 80%;
    }

    to {
      opacity: 0;
    }
  }
}
</style>
