import HttpClient from "@/api";

const state = {
  selectedTariffs: {},
  calculatedPrice: 0,
  months: 1,
  users: {},
  paymentMethod: "card",
  savedPaymentMethods: [],
  isPaymentAddding: false,
  isPurchasing: false,
  triggers: 0,
  selectedPaymentCheckId: null,
  accountsFormError: true,
};

const mutations = {
  SET_TARIFFS(store, payload) {
    if (Object.keys(store.selectedTariffs).length) return;
    payload.forEach((id) => {
      store.selectedTariffs[id] = 0;
      store.users[id] = [];
    });
  },

  SET_TARIFF(store, payload) {
    store.selectedTariffs[payload.id] = payload.count;
    store.users[payload.id] = new Array(payload.count).fill(() => "");
  },

  TRIGGER_VALIDATION(store) {
    store.triggers += 1;
  },

  RESET_TRIGGERS(store) {
    store.triggers = 0;
  },

  SET_ACCOUNTS_ERROR(store, payload) {
    store.accountsFormError = payload;
  },

  SET_MONTHS(store, payload) {
    store.months = payload;
  },

  UPDATE_USERS(store, payload) {
    store.users[payload.id] = [...payload.users];
  },

  UPDATE_PRICE(store, payload) {
    store.calculatedPrice = payload;
  },

  TOGGLE_PURCHASING(store, payload) {
    store.isPurchasing = payload;
  },

  UPDATE_PAYMENT_METHOD(store, payload) {
    store.selectedPaymentCheckId = null;
    store.paymentMethod = payload;
  },

  TOGGLE_PAYMENT_ADDING(store, payload) {
    store.isPageLoading = payload;
  },

  SET_PAYMENT_METHODS(store, payload) {
    store.savedPaymentMethods = payload;
  },

  SELECT_PAYMENT_CHECK(store, payload) {
    store.selectedPaymentCheckId = payload;
  },
};

const actions = {
  async pay({ commit, state }, payload) {
    try {
      commit("TOGGLE_PURCHASING", true);

      const tariffs = [];

      Object.entries(state.users).forEach((u) => {
        u[1].forEach((s) => {
          tariffs.push({
            id: u[0],
            user_phone: s,
          });
        });
      });

      const data = {
        email: payload.email,
        month_validity: state.months,
        tariffs,
      };

      if (state.selectedPaymentCheckId)
        data.bank_account_details_id = state.selectedPaymentCheckId;

      const response = await HttpClient.post(
        "api/corporate-tariff/purchase",
        data
      );
      return Promise.resolve(response.data.data.url);
    } catch (error) {
      return Promise.reject(false);
    } finally {
      commit("TOGGLE_PURCHASING", false);
    }
  },

  async loadPaymentMethods({ commit }) {
    try {
      const response = await HttpClient.get("api/bank-account-details");
      if (response.data?.data)
        commit("SET_PAYMENT_METHODS", response.data.data);
    } catch (error) {
      console.error(error);
    }
  },

  async addPayment({ commit, dispatch }, payload) {
    const { name, inn, type, bankName, bik, check, corCheck, address } =
      payload;
    try {
      commit("TOGGLE_PAYMENT_ADDING", true);
      await HttpClient.post("api/bank-account-details", {
        inn,
        organization_name: name,
        organization_type: type.name,
        legal_address: address,
        bank_name: bankName,
        bic: bik,
        number_account: check,
        corresp_account: corCheck,
      });
      await dispatch("loadPaymentMethods");
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      commit("TOGGLE_PAYMENT_ADDING", false);
    }
  },

  async calculate({ state, commit }) {
    try {
      const response = await HttpClient.post(`api/corporate-tariff/calculate`, {
        tariffs: Object.entries(state.selectedTariffs).map((t) => ({
          id: t[0],
          count: t[1],
        })),
        month_validity: state.months,
      });
      commit("UPDATE_PRICE", response.data.data.price);
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  allTariffs(state) {
    return Object.values(state.selectedTariffs).reduce(
      (acc, current) => acc + current,
      0
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
