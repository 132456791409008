<script setup>
import { computed, reactive } from "vue";
import { helpers, minLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import AppInput from "@/components/Ui/AppInput.vue";
import AppSelector from "@/components/Ui/AppSelector.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import OverrideMobileHeader from "@/components/Layouts/MainLayout/OverrideMobileHeader.vue";

const store = useStore();

const companyTypes = [
  {
    id: "IP",
    name: "ИП",
  },
  {
    id: "OAO",
    name: "ОАО",
  },
  {
    id: "OOO",
    name: "ООО",
  },
  {
    id: "PAO",
    name: "ПАО",
  },
];

const formData = reactive({
  name: "",
  inn: "",
  type: null,
  bankName: "",
  bik: "",
  check: "",
  corCheck: "",
  address: "",
  useAccountDetails: false,
});

const handleUseAccountDetails = (e) => {
  if (e.target.checked) {
    formData.inn = store.state.user.user?.inn ?? "";
    formData.name = store.state.user.user?.company ?? "";
  } else {
    formData.inn = "";
    formData.name = "";
  }
};

const rules = computed(() => ({
  formData: {
    name: {
      required: helpers.withMessage("Заполните поле", required),
    },
    inn: {
      required: helpers.withMessage("Заполните поле", required),
      min: helpers.withMessage("Неверный ИНН", minLength(10)),
    },
    type: {
      required: helpers.withMessage("Заполните поле", required),
    },
    bankName: {
      required: helpers.withMessage("Заполните поле", required),
    },
    bik: {
      required: helpers.withMessage("Заполните поле", required),
      min: helpers.withMessage("Неверный БИК", minLength(9)),
    },
    check: {
      required: helpers.withMessage("Заполните поле", required),
      min: helpers.withMessage("Неверный номер счета", minLength(24)),
    },
    corCheck: {
      required: helpers.withMessage("Заполните поле", required),
      min: helpers.withMessage("Неверный номер счета", minLength(24)),
    },
    address: {
      required: helpers.withMessage("Заполните поле", required),
    },
  },
}));

const v$ = useVuelidate(rules, { formData });

const { push } = useRouter();

const handleSubmit = async () => {
  v$.value.$validate();
  if (v$.value.$error) return;
  try {
    await store.dispatch("customTariff/addPayment", formData);
    store.commit("system/SET_NOTIFICATION", {
      type: "success",
      message: "Счет успешно добавлен",
    });
    push({ name: "ProfileCustomTariffPayment" });
  } catch (error) {
    store.commit("system/SET_NOTIFICATION", {
      type: "error",
      message: "Не удалось добавить счет",
    });
  }
};

const handleCancel = () => {
  push({ name: "ProfileCustomTariffPayment" });
};
</script>

<template>
  <OverrideMobileHeader
    :custom-action="{ title: 'Отмена' }"
    @action="handleCancel"
  />
  <div class="add-payment container mobile-paddings">
    <h1 class="title">Привязка счета</h1>
    <div class="description">Заполните юридические данные</div>
    <form class="form" @submit.prevent="handleSubmit">
      <AppInput
        id="c-inn"
        v-model="formData.inn"
        label="ИНН"
        :error="v$.formData.inn?.$errors[0]?.$message"
        input-mode="text"
        required
        mask="##########"
        :disabled="formData.useAccountDetails"
      />
      <AppInput
        id="c-name"
        v-model="formData.name"
        label="Название организации"
        :error="v$.formData.name?.$errors[0]?.$message"
        input-mode="text"
        required
        :disabled="formData.useAccountDetails"
      />
      <label class="app-checkbox use-account-details" for="region">
        <input
          id="region"
          v-model="formData.useAccountDetails"
          type="checkbox"
          @change="handleUseAccountDetails"
        />
        <span />
        Использовать данные аккаунта
      </label>
      <AppSelector
        label="Тип организации"
        placeholder="Не выбран"
        :options="companyTypes"
        required
        :error="v$.formData.type?.$errors[0]?.$message"
        :selected-option="formData.type"
        @select="formData.type = $event"
      />
      <AppInput
        id="c-address"
        v-model="formData.address"
        label="Юридический адрес"
        :error="v$.formData.address?.$errors[0]?.$message"
        input-mode="text"
        clear
        required
      />
      <AppInput
        id="c-bank-name"
        v-model="formData.bankName"
        label="Название банка"
        :error="v$.formData.bankName?.$errors[0]?.$message"
        input-mode="text"
        clear
        required
      />
      <AppInput
        id="c-bik"
        v-model="formData.bik"
        label="Бик банка"
        :error="v$.formData.bik?.$errors[0]?.$message"
        input-mode="text"
        mask="#########"
        clear
        required
      />
      <AppInput
        id="c-check"
        v-model="formData.check"
        label="Номер расчетного счета"
        :error="v$.formData.check?.$errors[0]?.$message"
        input-mode="text"
        mask="#### #### #### #### ####"
        clear
        required
      />
      <AppInput
        id="c-cor-check"
        v-model="formData.corCheck"
        label="Кор.счет"
        :error="v$.formData.corCheck?.$errors[0]?.$message"
        input-mode="text"
        mask="#### #### #### #### ####"
        clear
        required
      />
      <button
        :disabled="store.state.customTariff.isPaymentAddding"
        type="submit"
        class="button button_full-width submit"
      >
        Привязать
      </button>
    </form>
  </div>
</template>

<style scoped lang="scss">
.add-payment {
  .title {
    @include h-1;
  }

  .use-account-details {
    margin-bottom: $base-space * 2;
    display: flex;
    align-items: center;
  }

  .description {
    margin-top: $base-space * 4;
    @include m-text;
    color: $text-third;
  }

  .form {
    margin-top: $base-space * 8;
    display: flex;
    flex-direction: column;
    gap: $base-space * 3;

    .submit {
      margin-top: $base-space * 8;
    }
  }
}
</style>
