import HttpClient from "@/api";
import { getFilesFromPhotos } from "@/utils/files";

const state = {
  communities: [],
  myCommunity: [],
  myCommunitySubscriptions: [],
  allLinks: null,
  myLinks: null,
  subLinks: null,
  isAddingCommunity: false,
  isLoading: false,
  isLoadMoreLoading: false,
  isPageLoaded: false,
};

const mutations = {
  SET_MY_COMMUNITY(store, payload) {
    store.myCommunity = payload.items;
    store.myLinks = payload.links;
  },

  UPDATE_MY_COMMUNITY(store, payload) {
    store.myCommunity = [...store.myCommunity, ...payload.items];
    store.myLinks = payload.links;
  },

  SET_MY_COMMUNITY_SUBSCRIPTIONS(store, payload) {
    store.myCommunitySubscriptions = payload.items;
    store.subLinks = payload.links;
  },

  UPDATE_MY_COMMUNITY_SUBSCRIPTIONS(store, payload) {
    store.myCommunitySubscriptions = [
      ...store.myCommunitySubscriptions,
      ...payload.items,
    ];
    store.subLinks = payload.links;
  },

  SET_COMMUNITIES(store, payload) {
    store.communities = payload.items;
    store.allLinks = payload.links;
  },

  UPDATE_COMMUNITIES(store, payload) {
    store.communities = [...store.communities, ...payload.items];
    store.allLinks = payload.links;
  },

  RESET_COMMUNITIES(store) {
    store.communities = [];
    store.myCommunitySubscriptions = [];
    store.myCommunity = [];
    store.isLoading = false;
    store.isLoadMoreLoading = false;
    store.isPageLoaded = false;
  },

  TOGGLE_LOADING(store, payload) {
    store.isLoading = payload ?? !store.isLoading;
  },

  TOGGLE_LOAD_MORE_LOADING(store, payload) {
    store.isLoadMoreLoading = payload ?? !store.isLoadMoreLoading;
  },

  TOGGLE_PAGE_LOADING(store) {
    store.isPageLoaded = true;
  },

  SET_ADDING_COMMUNITY(store, payload) {
    store.isAddingCommunity = payload;
  },
};

const actions = {
  async loadCommunities(store, payload) {
    try {
      store.commit("TOGGLE_LOADING", true);
      const params = {};

      if (payload?.searchQuery) params.query = payload.searchQuery;

      const response = await HttpClient.get(`api/communities`, {
        params,
      });

      if (!response.data) throw new Error("No data");

      store.commit("SET_COMMUNITIES", {
        items: response.data.data,
        links: response.data.links,
      });
    } catch (error) {
      console.error(error);
    } finally {
      store.commit("TOGGLE_LOADING", false);
    }
  },

  async loadNextCommunities(store) {
    try {
      const nextLink = store.state.allLinks?.next;
      if (!nextLink) return;

      store.commit("TOGGLE_LOAD_MORE_LOADING", true);

      const response = await HttpClient.get(nextLink);

      store.commit("UPDATE_COMMUNITIES", {
        items: response.data.data,
        links: response.data.links,
      });
    } catch (error) {
      console.error(error);
    } finally {
      store.commit("TOGGLE_LOAD_MORE_LOADING", false);
    }
  },

  async addCommunity({ commit }, payload) {
    try {
      commit("SET_ADDING_COMMUNITY", true);
      const {
        name,
        photos,
        shortDescription,
        leader,
        description,
        socialNetworks,
      } = payload;
      const response = await HttpClient.postForm(`api/communities`, {
        name,
        text: description,
        short_text: shortDescription,
        leader,
        file: getFilesFromPhotos(photos)[0],
        region_id: 1,
        social_networks: socialNetworks,
      });
      return Promise.resolve(response.data?.data?.id);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      commit("SET_ADDING_COMMUNITY", false);
    }
  },

  async getMyCommunity(store) {
    try {
      store.commit("TOGGLE_LOADING", true);
      const response = await HttpClient.get("api/me/communities/my");

      store.commit("SET_MY_COMMUNITY", {
        items: response.data.data,
        links: response.data.links,
      });

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING", false);
    }
  },

  async getNextMyCommunity(store) {
    try {
      const nextLink = store.state.myLinks?.next;

      if (!nextLink) return;
      store.commit("TOGGLE_LOAD_MORE_LOADING", true);

      const response = await HttpClient.get(nextLink);

      store.commit("UPDATE_MY_COMMUNITY", {
        items: response.data.data,
        links: response.data.links,
      });

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOAD_MORE_LOADING", false);
    }
  },

  async getMyCommunitySubscriptions(store) {
    try {
      store.commit("TOGGLE_LOADING", true);
      const response = await HttpClient.get("api/me/communities/joined");

      store.commit("SET_MY_COMMUNITY_SUBSCRIPTIONS", {
        items: response.data.data,
        links: response.data.links,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING", false);
    }
  },

  async getNextMyCommunitySubscriptions(store) {
    try {
      const nextLink = store.state.subLinks?.next;
      if (!nextLink) return;

      store.commit("TOGGLE_LOAD_MORE_LOADING", true);

      const response = await HttpClient.get(nextLink);

      store.commit("UPDATE_MY_COMMUNITY_SUBSCRIPTIONS", {
        items: response.data.data,
        links: response.data.links,
      });

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOAD_MORE_LOADING", false);
    }
  },

  async editCommunity({ commit }, formData) {
    const id = formData.get("id");

    const normalize = formData;
    normalize.delete("id");

    try {
      const response = await HttpClient.post(
        `api/communities/${id}`,
        normalize
      );
      return Promise.resolve(response.data?.data?.id);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      commit("SET_ADDING_COMMUNITY", false);
    }
  },

  deleteModerationText(_, community_id) {
    return new Promise((resolve, reject) => {
      HttpClient.post(`api/communities/${community_id}/dismiss-moderation`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
