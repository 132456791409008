<script>
import UserAvatar from "@/components/UserAvatar.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import AppModal from "@/components/Ui/AppModal.vue";
import NotificationsSettings from "@/components/Profile/NotificationsSettings.vue";
import SupportRequest from "@/components/Profile/SupportRequest.vue";
import moment from "moment";
import CooperationRequest from "@/components/Profile/CooperationRequest.vue";
import QrModal from "@/components/Profile/QrModal.vue";
import TextButton from "@/components/Ui/TextButton.vue";

export default {
  name: "ProfileCard",

  components: {
    TextButton,
    QrModal,
    CooperationRequest,
    SupportRequest,
    NotificationsSettings,
    AppModal,
    UserAvatar,
  },

  emits: ["close"],

  data() {
    return {
      show_exit_modal: false,
      show_notifications_modal: false,
      show_support_modal: false,
      show_cooperation_modal: false,
      show_representation_block: false,
      qrModal: false,

      navigation: [
        { link: "ProfileEdit", title: "Редактировать профиль", icon: "edit" },
        // {
        //   link: "ProfileNotificationsSettings",
        //   title: "Настройка уведомлений",
        //   icon: "settings",
        // },
        {
          link: "ProfileInstructionsView",
          title: "Инструкции",
          icon: "acts",
        },
        { link: "ProfileSupport", title: "Техподдержка", icon: "faq" },
        {
          link: "ProfileCooperation",
          title: "Сотрудничество",
          icon: "business",
        },
      ],

      invited_users: "",
      invited_users_with_tariff: "",
    };
  },

  computed: {
    ...mapState({
      window_width: (state) => state.system.window_width,
      user: (state) => state.user.user,
      promo_codes: (state) => state.user.promo_codes,
      statisticRepresentation: (state) =>
        state.regionalRepresentative.statisticRepresentation,
    }),

    currentInvitedUsers() {
      return this.statisticRepresentation.invited_users;
    },

    currentReward() {
      return this.statisticRepresentation?.reward;
    },

    currentRepresentation() {
      return this.statisticRepresentation.representation?.code;
    },

    currentActiveMembers() {
      return (
        this.currentInvitedUsers?.resident_count +
        this.currentInvitedUsers?.master_candidate_count +
        this.currentInvitedUsers?.master_count
      );
    },

    refLink() {
      const queries = {
        invite_code: this.promo_codes[0]?.code ?? "",
        representation_code: this.user.representation?.code ?? "",
      };

      const params = new URLSearchParams(queries).toString();

      return import.meta.env.VITE_APP_FRONT_URL + "login/auth?" + params;
    },
  },

  methods: {
    ...mapActions({
      createUserPromoCode: "user/createUserPromoCode",
      loadUserInformation: "user/loadUserInformation",
      loadUserStatistic: "user/loadUserStatistic",
      getStatisticsCurrentUser:
        "regionalRepresentative/getStatisticsCurrentUser",
    }),

    ...mapMutations({
      setNotification: "system/SET_NOTIFICATION",
    }),

    formatPayedDate(date) {
      return moment(date).format("DD MMM");
    },

    getLeftDay(date) {
      return moment(date).diff(moment(), "days");
    },

    selectProfileNavigationItem(item) {
      switch (item.link) {
        case "ProfileEdit": {
          this.$router.push({ name: "ProfileEdit" });
          this.$emit("close");
          break;
        }
        // case "ProfileNotificationsSettings": {
        //   if (this.window_width > 1023) {
        //     this.show_notifications_modal = true;
        //     return;
        //   }

        //   this.$router.push({ name: "ProfileNotificationsSettings" });
        //   break;
        // }
        case "ProfileInstructionsView": {
          this.$router.push({ name: "ProfileInstructionsView" });
          this.$emit("close");
          break;
        }
        case "ProfileSupport": {
          if (this.window_width > 1023) {
            this.show_support_modal = true;
            return;
          }
          this.$router.push({ name: "ProfileSupport" });
          break;
        }
        case "ProfileCooperation": {
          if (this.window_width > 1023) {
            this.show_cooperation_modal = true;
            return;
          }

          this.$router.push({ name: "ProfileCooperation" });
          break;
        }
      }
    },

    openStatistics() {
      this.$router.push({ name: "ProfileRegionalRepresentativeView" });
    },

    createCode() {
      if (!this.button_loader) {
        this.button_loader = true;

        this.createUserPromoCode()
          .catch(() => {
            this.setNotification({
              type: "error",
              message: "Не удалось создать код",
            });
          })
          .finally(() => {
            this.button_loader = false;
          });
      }
    },

    logoutUser() {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenWs");
      window.location.reload();
    },

    successNotice() {
      this.setNotification({
        type: "success",
        message: "Скопировано",
      });
    },

    openQrModal() {
      this.qrModal = !this.qrModal;
    },

    closeQrModalHandler() {
      this.qrModal = false;
    },
  },

  beforeMount() {
    this.loadUserInformation();
    this.loadUserStatistic().then((response) => {
      this.invited_users = response.data.data.invited_users;
      this.invited_users_with_tariff =
        response.data.data.invited_users_with_tariff;
    });
    if (this.user.representation !== null) {
      this.getStatisticsCurrentUser();
      this.show_representation_block = true;
    }
  },
};
</script>

<template>
  <AppModal
    v-if="show_notifications_modal"
    @close="show_notifications_modal = false"
  >
    <NotificationsSettings @close="show_notifications_modal = false" />
  </AppModal>

  <AppModal v-if="show_support_modal" @close="show_support_modal = false">
    <SupportRequest @close="show_support_modal = false" />
  </AppModal>

  <AppModal
    v-if="show_cooperation_modal"
    @close="show_cooperation_modal = false"
  >
    <CooperationRequest @close="show_cooperation_modal = false" />
  </AppModal>

  <AppModal v-if="show_exit_modal" @close="show_exit_modal = false">
    <div class="notification-modal">
      <img src="../../assets/images/exit.svg" alt="exit-image" />

      <h3>Выйти из аккаунта?</h3>

      <div class="notification-modal__actions">
        <button type="button" class="button" @click="logoutUser">Выйти</button>
        <button
          type="button"
          class="button button_secondary"
          @click="show_exit_modal = false"
        >
          Отмена
        </button>
      </div>
    </div>
  </AppModal>

  <QrModal
    v-if="qrModal"
    :url-src="refLink"
    @closeModal="closeQrModalHandler"
  ></QrModal>

  <div class="profile">
    <section class="profile__user user">
      <UserAvatar />

      <div class="user__info">
        <h5>{{ user.first_name }} {{ user.last_name }}</h5>
        <p><i class="icon-star" /> {{ user.points }} бонусов</p>
      </div>
    </section>

    <RouterLink
      :to="{ name: 'ProfileTariffs' }"
      class="profile__status"
      :class="`profile__status_${user.tariff?.code}`"
    >
      <header v-if="user.tariff_id > 1">
        <p
          class="status-label"
          :class="`status-label_${user.tariff?.code}_light`"
        >
          <i
            :class="`icon-${
              user.tariff?.code === 'member'
                ? 'person'
                : user.tariff?.code === 'resident'
                  ? 'premium'
                  : 'vip'
            }`"
          />
          {{ user.tariff.name }}
        </p>

        <span>
          {{
            user.corporate_tariff?.is_current_tariff_corporate
              ? "Корпоративный"
              : "Активен"
          }}
        </span>
      </header>

      <h4 v-else>Статус РДО</h4>

      <p v-if="user.tariff_id > 1">
        <span v-if="!user.tariff_is_cancelled && user.tariff_is_trial">
          {{ user.tariff.price_original }} ₽ спишется
          {{ formatPayedDate(user.tariff_payed_until) }}
        </span>

        <span v-else>
          Статус активен до
          {{ formatPayedDate(user.tariff_payed_until) }}
        </span>
      </p>

      <span v-if="user.tariff_is_trial" class="text-button text-button_accent">
        {{ user.tariff_id > 1 ? "Управление подпиской" : "Повысить статус" }}
        <i class="icon-arrow-right-1" />
      </span>

      <p
        v-if="user.tariff_is_trial && user.tariff.id > 1"
        :class="{
          'notification_left-day': user.tariff_is_trial && user.tariff.id > 1,
        }"
      >
        <span> Пробный период </span>
        <span> еще {{ getLeftDay(user.tariff_payed_until) }} дней </span>
      </p>

      <span v-if="!user.tariff_is_trial" class="text-button text-button_accent">
        {{ user.tariff_id > 1 ? "Управление подпиской" : "Повысить статус" }}
        <i class="icon-arrow-right-1" />
      </span>

      <p v-if="!user.tariff_is_trial">
        "Повысьте статус, чтобы получить доступ к новым мероприятиям"
      </p>
    </RouterLink>

    <section v-if="user.company" class="profile__legal">
      <h5>{{ user.company }}</h5>
      <p>{{ user.position }}</p>
    </section>

    <section
      v-if="show_representation_block"
      class="profile__code-representative"
    >
      <div class="profile__representative-statistics-header">
        <h4>Представительство</h4>
      </div>

      <div class="profile__representative-statistics-body">
        <p>
          Код представительства
          <span> {{ currentRepresentation }} </span>
        </p>
        <p>
          Всего участников <span> {{ currentInvitedUsers?.total }} </span>
        </p>
        <p>
          Активных подписок
          <span> {{ currentActiveMembers }}</span>
        </p>
        <p>
          Возгнаграждение за месяц
          <span> {{ currentReward }} </span>
        </p>
      </div>

      <TextButton
        size="medium"
        label="Статистика по приглашенным"
        icon="arrow-right-1"
        icon-position="right"
        @click="openStatistics"
      />
    </section>

    <section class="profile__code">
      <div class="profile__invite">
        <h5>Инвайт-код:</h5>
        <template v-if="promo_codes.length">
          <p>{{ promo_codes[0]?.code }}</p>
        </template>
        <button v-else type="button" @click="createCode">Сгенерировать</button>
      </div>

      <div class="profile__statistic">
        <p>
          Приглашенных пользователей <span>{{ invited_users }}</span>
        </p>
        <p>
          Активных подписок <span>{{ invited_users_with_tariff }}</span>
        </p>
      </div>
    </section>

    <section v-if="refLink" class="profile__code">
      <div class="profile__invite">
        <h5>Реферальная ссылка:</h5>
      </div>

      <a :href="refLink" target="_blank" class="profile__ref-link">
        {{ refLink }}
      </a>

      <div class="profile__invite-ref">
        <button
          v-clipboard:copy="refLink"
          type="button"
          class="text-button text-button_accent"
          @click="successNotice"
        >
          Скопировать <i class="icon-copy"></i>
        </button>

        <button
          type="button"
          class="text-button text-button_accent"
          @click="openQrModal"
        >
          Сформировать QR <i class="icon-qr-code"></i>
        </button>
      </div>
    </section>

    <nav>
      <ul>
        <li v-for="item in navigation" :key="item.link">
          <button type="button" @click="selectProfileNavigationItem(item)">
            <i :class="`icon-${item.icon}`" />
            <span>{{ item.title }}</span>
            <i class="icon-arrow-right-1" />
          </button>
        </li>
      </ul>

      <button type="button" @click.stop="show_exit_modal = true">
        <i class="icon-exit" />Выйти
      </button>
    </nav>
  </div>
</template>

<style scoped lang="scss">
.profile {
  height: calc(100dvh - 180px);
  overflow-y: scroll;
  > nav {
    margin: ($base-space * 6) 0 0;

    > ul {
      display: grid;
      grid-template-columns: 1fr;

      > li {
        &:not(:last-of-type) {
          border-bottom: 1px solid $stroke-fourth;
        }

        &:hover {
          > button {
            color: $text-accent;

            > i {
              &:first-of-type {
                background: $text-accent;
              }

              &:last-of-type {
                background: $text-accent;
              }
            }
          }
        }

        > button {
          display: grid;
          grid-template-columns: 24px 1fr 24px;
          justify-items: start;
          align-items: center;
          grid-gap: $base-space;
          padding: ($base-space * 5) 0;
          color: $text-second;
          @include m-text;
          width: 100%;

          > i {
            width: 24px;
            height: 24px;

            &:first-of-type {
              background: $text-primary;
            }

            &:last-of-type {
              background: $text-fourth;
            }
          }
        }
      }
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $base-space;
      @include m-text;
      color: $text-error;
      background: none;
      border: none;
      padding: ($base-space * 5) 0;

      > i {
        width: 24px;
        height: 24px;
        background: $text-error;
      }
    }
  }

  .notification_left-day {
    @include s-text;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    width: 100%;
    height: auto;
    border: 1px solid $text-light;
    border-radius: 12px;
    background: transparent;
    color: $text-light;
  }

  &__legal {
    padding: ($base-space * 5);
    border: 1px solid $stroke-fourth;
    border-radius: 16px;
    margin: ($base-space * 2) 0 0;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: $base-space;

    > h5 {
      @include s-text;
      color: $text-primary;
    }

    > p {
      @include s-text;
      color: $text-fourth;
    }
  }

  &__code {
    padding: ($base-space * 5);
    border: 1px solid $stroke-fourth;
    border-radius: 16px;
    margin: ($base-space * 2) 0 0;
  }

  &__code-representative {
    padding: ($base-space * 5);
    border: 1px solid $stroke-fourth;
    border-radius: 16px;
    margin: ($base-space * 2) 0 0;
  }

  &__representative-statistics-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h4 {
      @include h-4;
      color: $text-primary;
    }

    > p {
      @include h-4;
      color: $text-primary;
    }

    > button {
      color: $text-accent;
      @include s-text;
    }
  }

  &__representative-statistics-body {
    margin: ($base-space * 4) 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $base-space * 2;

    > p {
      @include s-text;
      color: $text-third;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > span {
        color: $text-primary;
        @include h-5;
      }
    }
  }

  &__invite {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h5 {
      @include s-text;
      color: $text-third;
    }

    > p {
      @include h-4;
      color: $text-primary;
    }

    > button {
      color: $text-accent;
      @include s-text;
    }
  }

  &__invite-ref {
    display: flex;
    align-items: center;
    margin-top: 8px;
    gap: 16px;

    @media (max-width: 374px) {
      flex-direction: column;
      justify-content: left;
      align-items: flex-start;
      gap: 8px;
    }

    > h5 {
      @include s-text;
      color: $text-third;
    }

    > p {
      @include h-4;
      color: $text-primary;
    }

    > button {
      color: $text-accent;
      @include s-text;
    }
  }

  &__statistic {
    border-top: 1px solid $stroke-fourth;
    padding: ($base-space * 4) 0 0;
    margin: ($base-space * 4) 0 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $base-space * 2;

    > p {
      @include s-text;
      color: $text-third;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > span {
        color: $text-primary;
        @include h-5;
      }
    }
  }

  &__ref-link {
    display: inline-block;
    margin-top: 8px;
    color: $text-primary;
    @include s-text;
    word-break: break-all;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.user {
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: $base-space * 4;
  align-items: center;

  &__info {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: $base-space;

    > h5 {
      @include h-2;
      color: $text-primary;
    }

    > p {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $base-space;
      color: $text-fourth;

      > i {
        width: 20px;
        height: 20px;
        background: $text-fourth;
      }
    }
  }
}

.profile__status {
  margin: ($base-space * 8) 0 0;
  padding: ($base-space * 5);
  background: $background-light-accent;
  border-radius: 16px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: $base-space * 2;

  > header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
      color: $text-light;
      @include s-text;
    }
  }

  > h4 {
    @include h-4;
    color: $text-primary;
  }

  > p {
    color: $text-third;
  }

  &_resident {
    background: url("@/assets/backgrounds/resident.svg") no-repeat
      $tariffs-resident-background right bottom;
    background-size: contain;

    > p {
      color: $tariffs-resident-secondary-text;
    }

    > span {
      color: $text-light;

      > i {
        background-color: $text-light;
      }
    }
  }

  &_master-candidate {
    background: url("@/assets/backgrounds/master-candidate.svg") no-repeat
      $tariffs-master-candidate-background right bottom;
    background-size: contain;

    > p {
      color: $tariffs-master-candidate-secondary-text;
    }

    > span {
      color: $text-light;

      > i {
        background-color: $text-light;
      }
    }
  }

  &_master {
    background: url("@/assets/backgrounds/master.svg") no-repeat
      $tariffs-master-background right bottom;
    background-size: contain;

    > p {
      color: $tariffs-master-secondary-text;
    }

    > span {
      color: $text-light;

      > i {
        background-color: $text-light;
      }
    }
  }
}
</style>
