import HttpClient from "@/api";

const state = {
  service: null,
  isLoading: false,
};

const mutations = {
  RESET_SERVICE(store) {
    store.service = null;
    store.isLoading = false;
  },

  UPDATE_SERVICE(store, payload) {
    store.service = payload.service;
  },

  TOGGLE_LOADING(store, payload) {
    store.isLoading = payload ?? !store.isLoading;
  },
};

const actions = {
  async loadService(store, payload) {
    try {
      store.commit("TOGGLE_LOADING", true);

      const response = await HttpClient.get(
        `api/useful-services/${payload.id}`
      );
      if (!response.data) throw new Error("No data");
      store.commit("UPDATE_SERVICE", {
        service: response.data.data,
      });
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
