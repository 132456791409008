<script setup>
import ContentBox from "@/components/Ui/ContentBox.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import wordDeclension from "../../package/helpers/wordDeclension";
import { useRoute, useRouter } from "vue-router";

const { name } = useRoute();

const { push } = useRouter();

const steps = [
  "ProfileCustomTariffSelect",
  "ProfileCustomTariffAccounts",
  "ProfileCustomTariffPayment",
];

const priceFormatter = new Intl.NumberFormat("ru-RU", {
  style: "currency",
  currency: "RUB",
});

const price = computed(() =>
  priceFormatter.format(store.state.customTariff.calculatedPrice)
);

const store = useStore();

const handleNext = () => {
  store.commit("customTariff/TRIGGER_VALIDATION");
  const currentStepIdx = steps.indexOf(name);
  const next = steps[currentStepIdx + 1];
  if (next) push({ name: next });
};
</script>

<template>
  <ContentBox class="panel">
    <div class="row">
      <div class="title">
        {{ store.getters["customTariff/allTariffs"] }}
        {{
          wordDeclension(store.getters["customTariff/allTariffs"], [
            "тариф",
            "тарифа",
            "тарифов",
          ])
        }}
      </div>
      <div class="title">{{ price }} / мес.</div>
    </div>
    <div class="row">
      <div class="description">
        на {{ store.state.customTariff.months }}
        {{
          wordDeclension(store.state.customTariff.months, [
            "месяц",
            "месяца",
            "месяцев",
          ])
        }}
      </div>
      <del class="description" :style="{ visibility: 'hidden' }"
        >{{ price }} / мес.</del
      >
    </div>
    <div class="action">
      <button
        type="button"
        class="button button_full-width"
        @click="handleNext"
      >
        Далее
      </button>
    </div>
  </ContentBox>
</template>

<style scoped lang="scss">
.panel {
  display: flex;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  z-index: 10;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }

  .title {
    @include h-2;
  }
  .description {
    @include m-text;
    color: $text-fifth;
  }
  .action {
    margin-top: $base-space * 3;
  }
}
</style>
