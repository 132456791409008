import { createRouter, createWebHistory } from "vue-router";
import MainLayout from "@/components/Layouts/MainLayout/MainLayout.vue";
import ClearLayout from "@/components/Layouts/ClearLayout.vue";
import CustomTariffLayout from "@/components/Layouts/CustomTariffLayout/CustomTariffLayout.vue";
import CustomTariffSelectView from "@/views/CustomTariff/CustomTariffSelectView.vue";
import CustomTariffAccountsView from "@/views/CustomTariff/CustomTariffAccountsView.vue";
import CustomTariffPaymentView from "@/views/CustomTariff/CustomTariffPaymentView.vue";
import CustomTariffAddPaymentView from "@/views/CustomTariff/CustomTariffAddPaymentView.vue";
import CustomTariffSuccessView from "@/views/CustomTariff/CustomTariffSuccessView.vue";

const routes = [
  {
    path: "/",
    component: ClearLayout,
    children: [
      {
        path: "",
        component: ClearLayout,
        beforeEnter: (to, from, next) => {
          if (localStorage.token) {
            next({ name: "EventsView" });
            return;
          }

          next({ name: "AuthView" });
        },
      },
      {
        path: "/login",
        component: ClearLayout,
        children: [
          {
            path: "",
            name: "AuthView",
            component: () => import("@/views/Auth/AuthView.vue"),
          },
          {
            path: "auth",
            name: "LoginView",
            component: () => import("@/views/Auth/LoginView.vue"),
          },
        ],
      },
      {
        path: "/events",
        component: MainLayout,
        beforeEnter: (to, from, next) => {
          if (!localStorage.token) {
            next({ name: "AuthView" });
            return;
          }

          next();
        },
        children: [
          {
            path: "",
            name: "EventsView",
            component: () => import("@/views/Events/EventsView.vue"),
          },
          {
            path: ":id",
            name: "EventView",
            component: () => import("@/views/Events/EventView.vue"),
          },
        ],
      },
      {
        path: "/news",
        component: MainLayout,
        beforeEnter: (to, from, next) => {
          if (!localStorage.token) {
            next({ name: "AuthView" });
            return;
          }

          next();
        },
        children: [
          {
            path: "",
            name: "NewsView",
            component: () => import("@/views/News/NewsView.vue"),
          },
          {
            path: ":id",
            name: "ArticleView",
            component: () => import("@/views/News/ArticleView.vue"),
          },
        ],
      },
      {
        path: "/messenger",
        component: MainLayout,
        beforeEnter: (to, from, next) => {
          if (!localStorage.token) {
            next({ name: "AuthView" });
            return;
          }

          next();
        },
        children: [
          {
            path: "",
            name: "ChatChannelsView",
            component: () => import("@/views/Chat/ChatChannelsView.vue"),
          },
          {
            path: ":id",
            name: "ChatCurrentChannelView",
            component: () => import("@/views/Chat/ChatChannelsView.vue"),
          },
          {
            path: "/messenger/channel",
            name: "CreateChannelView",
            component: () => import("@/views/Channel/EditChannelView.vue"),
            props: { isCreate: true },
          },
          {
            path: "/messenger/private_channel",
            name: "CreatePrivateChannelView",
            component: () => import("@/views/Channel/EditChannelView.vue"),
            props: { isCreate: true },
          },
          {
            path: "/messenger/channel/:id",
            name: "EditChannelView",
            component: () => import("@/views/Channel/EditChannelView.vue"),
            props: { isCreate: false },
          },
        ],
      },
      {
        path: "/profile",
        component: MainLayout,
        beforeEnter: (to, from, next) => {
          if (!localStorage.token) {
            next({ name: "AuthView" });
            return;
          }

          next();
        },
        children: [
          {
            path: "",
            name: "ProfileView",
            component: () => import("@/views/Profile/ProfileView.vue"),
          },
          {
            path: "edit",
            name: "ProfileEdit",
            component: () => import("@/views/Profile/ProfileEditView.vue"),
          },
          {
            path: "representative",
            name: "ProfileRegionalRepresentativeView",
            component: () =>
              import("@/views/Profile/ProfileRegionalRepresentativeView.vue"),
          },
          {
            path: "instructions",
            name: "ProfileInstructionsView",
            component: () =>
              import("@/views/Profile/ProfileInstructionsView.vue"),
          },
          {
            path: "support",
            name: "ProfileSupport",
            component: () => import("@/views/Profile/ProfileSupportView.vue"),
          },
          {
            path: "cooperation",
            name: "ProfileCooperation",
            component: () => import("@/views/Profile/ProfileCooperation.vue"),
          },
          {
            path: "tariffs",
            name: "ProfileTariffs",
            component: () => import("@/views/Profile/ProfileTariffsView.vue"),
          },
          {
            path: "tariffs/custom",
            name: "ProfileCustomTariff",
            component: CustomTariffLayout,
            children: [
              {
                path: "select",
                name: "ProfileCustomTariffSelect",
                component: CustomTariffSelectView,
              },
              {
                path: "accounts",
                name: "ProfileCustomTariffAccounts",
                component: CustomTariffAccountsView,
              },
              {
                path: "pay",
                name: "ProfileCustomTariffPayment",
                component: CustomTariffPaymentView,
              },
            ],
          },
          {
            path: "tariffs/custom/add-payment",
            name: "ProfileCustomTariffAddPayment",
            component: CustomTariffAddPaymentView,
          },
          {
            path: "tariffs/custom/success",
            name: "ProfileCustomTariffSuccess",
            component: CustomTariffSuccessView,
          },
        ],
      },
      {
        path: "/notifications",
        component: MainLayout,
        beforeEnter: (to, from, next) => {
          if (!localStorage.token) {
            next({ name: "AuthView" });
            return;
          }

          next();
        },
        children: [
          {
            path: "",
            name: "ProfileNotifications",
            component: () =>
              import("@/views/Profile/ProfileNotificationsView.vue"),
          },
          {
            path: ":id",
            name: "ProfileNotification",
            component: () =>
              import("@/views/Profile/ProfileNotificationView.vue"),
          },
        ],
      },

      {
        path: "/calendar",
        component: MainLayout,
        beforeEnter: (to, from, next) => {
          if (!localStorage.token) {
            next({ name: "AuthView", query: { ...to.query } });
            return;
          }

          next();
        },
        children: [
          {
            path: "",
            name: "CalendarView",
            component: () => import("@/views/Calendar/CalendarView.vue"),
          },
          {
            path: "/day",
            name: "CalendarDayView",
            component: () => import("@/views/Calendar/CalendarDayView.vue"),
          },
          {
            path: "/:id",
            name: "CalendarPageView",
            component: () => import("@/views/Calendar/CalendarPageView.vue"),
          },
        ],
      },
      {
        path: "/services",
        component: MainLayout,
        beforeEnter: (to, from, next) => {
          if (!localStorage.token) {
            next({ name: "AuthView" });
            return;
          }

          next();
        },
        children: [
          {
            path: "",
            name: "ServicesView",
            component: () => import("@/views/Services/ServicesView.vue"),
          },
          {
            path: "services/:id",
            name: "Service",
            component: () => import("@/views/Services/ServiceView.vue"),
          },
        ],
      },
      {
        path: "/sos-button",
        component: MainLayout,
        beforeEnter: (to, from, next) => {
          if (!localStorage.token) {
            next({ name: "AuthView" });
            return;
          }

          next();
        },
        children: [
          {
            path: "",
            name: "SosView",
            component: () => import("@/views/SosView.vue"),
          },
        ],
      },
      {
        path: "/communities",
        component: MainLayout,
        beforeEnter: (to, from, next) => {
          if (!localStorage.token) {
            next({ name: "AuthView" });
            return;
          }

          next();
        },
        children: [
          {
            path: "",
            name: "CommunitiesView",
            component: () => import("@/views/Communities/CommunitiesView.vue"),
          },
          {
            path: "communities/add",
            name: "AddCommunity",
            component: () => import("@/views/Communities/AddCommunityView.vue"),
          },

          {
            path: "communities/edit/:id",
            name: "EditCommunity",
            component: () =>
              import("@/views/Communities/EditCommunityView.vue"),
          },

          {
            path: "communities/:id",
            name: "Community",
            component: () => import("@/views/Communities/CommunityView.vue"),
          },
          {
            path: "communities/:communityId/events",
            name: "CommunityEvents",
            component: () => import("@/views/Events/EventsView.vue"),
          },
          {
            path: "communities/:communityId/events/:id",
            name: "CommunityEvent",
            component: () => import("@/views/Events/EventView.vue"),
          },
          {
            path: "communities/:id/events/add",
            name: "AddCommunityEvent",
            component: () => import("@/views/Events/AddEventView.vue"),
          },
          {
            path: "communities/:id/posts/add",
            name: "AddCommunityPostView",
            component: () =>
              import("@/views/Communities/AddCommunityPostView.vue"),
          },
          {
            path: "communities/:communityId/posts/:id",
            name: "CommunityPostView",
            component: () =>
              import("@/views/Communities/CommunityPostView.vue"),
          },
          {
            path: "communities/:id/about",
            name: "AboutCommunityView",
            component: () =>
              import("@/views/Communities/AboutCommunityView.vue"),
          },
        ],
      },
      {
        path: "/companies",
        component: MainLayout,
        beforeEnter: (to, from, next) => {
          if (!localStorage.token) {
            next({ name: "AuthView" });
            return;
          }

          next();
        },
        children: [
          {
            path: "",
            name: "CompaniesView",
            component: () => import("@/views/Companies/CompaniesView.vue"),
          },
          {
            path: "companies/credentials",
            name: "CompanyNeedCredentials",
            component: () =>
              import("@/views/Companies/AddCompanyNeedCredentialsView.vue"),
          },
          {
            path: "companies/:id",
            name: "Company",
            component: () => import("@/views/Companies/CompanyView.vue"),
          },
        ],
      },

      {
        path: "/support-centers",
        component: MainLayout,
        beforeEnter: (to, from, next) => {
          if (!localStorage.token) {
            next({ name: "AuthView" });
            return;
          }

          next();
        },
        children: [
          {
            path: "",
            name: "SupportCentersView",
            component: () =>
              import("@/views/SupportCenter/SupportCentersView.vue"),
          },

          {
            path: "support-centers/submit",
            name: "SupportCentersSubmit",
            component: () =>
              import("@/views/SupportCenter/SupportCenterSubmitView.vue"),
          },
        ],
      },
      {
        path: "/partners",
        component: MainLayout,
        beforeEnter: (to, from, next) => {
          if (!localStorage.token) {
            next({ name: "AuthView" });
            return;
          }

          next();
        },
        children: [
          {
            path: "",
            name: "InvestmentProgramView",
            component: () =>
              import("@/views/InvestmentProgram/InvestmentProgramView.vue"),
          },
          {
            path: "partners/partners-type/add",
            name: "ChoiceInvestmentProgramView",
            component: () =>
              import(
                "@/views/InvestmentProgram/ChoiceInvestmentProgramView.vue"
              ),
          },

          {
            path: "partners/add",
            name: "AddInvestmentProgram",
            component: () =>
              import("@/views/InvestmentProgram/AddInvestmentProgramView.vue"),
          },

          {
            path: "partners/edit/:id",
            name: "EditInvestmentProgram",
            component: () =>
              import("@/views/InvestmentProgram/EditInvestmentProgramView.vue"),
          },

          {
            path: "partners/partners-page/:id",
            name: "InvestmentPageView",
            component: () =>
              import("@/views/InvestmentProgram/InvestmentPageView.vue"),
          },
        ],
      },
      {
        path: "/courses",
        component: MainLayout,
        beforeEnter: (to, from, next) => {
          if (!localStorage.token) {
            next({ name: "AuthView" });
            return;
          }

          next();
        },
        children: [
          {
            path: "",
            name: "CoursesView",
            component: () => import("@/views/Courses/CoursesView.vue"),
          },
          {
            path: "courses/:id",
            name: "Course",
            component: () => import("@/views/Courses/CourseView.vue"),
          },
          {
            path: "courses/:courseId/events",
            name: "CourseEvents",
            component: () => import("@/views/Events/EventsView.vue"),
          },
          {
            path: "courses/:courseId/events/:id",
            name: "CourseEvent",
            component: () => import("@/views/Events/EventView.vue"),
          },
        ],
      },
      {
        path: "/author-courses",
        component: MainLayout,
        beforeEnter: (to, from, next) => {
          if (!localStorage.token) {
            next({ name: "AuthView" });
            return;
          }

          next();
        },
        children: [
          {
            path: "",
            name: "AuthorCoursesView",
            component: () =>
              import("@/views/AuthorCourses/AuthorCoursesView.vue"),
          },
          {
            path: "author-courses/:id",
            name: "AuthorCourseView",
            component: () =>
              import("@/views/AuthorCourses/AuthorCourseView.vue"),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  scrollBehavior() {
    return { top: 0 };
  },
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
