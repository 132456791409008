<script>
import AppModal from "@/components/Ui/AppModal.vue";
import AppDrawer from "@/components/Ui/AppDrawer.vue";
import { mapState } from "vuex";

export default {
  name: "MainNotice",
  components: { AppDrawer, AppModal },
  emits: ["close", "submit", "cancel"],

  props: {
    isShowNotice: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "",
    },

    content: {
      type: String,
      default: "",
    },

    submitText: {
      type: String,
      default: "Сохранить",
    },

    submitDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      window_width: (state) => state.system.window_width,
    }),
  },
};
</script>

<template>
  <AppModal v-if="isShowNotice && window_width > 1023" @close="$emit('close')">
    <div class="main-notice">
      <h4>{{ title }}</h4>

      <p>{{ content }}</p>

      <div class="main-notice__footer">
        <button
          type="button"
          class="button"
          :disabled="submitDisabled"
          @click="$emit('submit')"
        >
          {{ submitText }}
        </button>

        <button
          class="button button_secondary"
          type="button"
          @click="$emit('close')"
        >
          Отмена
        </button>
      </div>
    </div>
  </AppModal>

  <AppDrawer v-else-if="isShowNotice" @close="$emit('close')">
    <div class="main-notice">
      <h4>{{ title }}</h4>

      <p>{{ content }}</p>

      <div class="main-notice__footer">
        <button
          type="button"
          class="button"
          :disabled="submitDisabled"
          @click="$emit('submit')"
        >
          {{ submitText }}
        </button>

        <button
          class="button button_secondary"
          type="button"
          @click="$emit('close')"
        >
          Отмена
        </button>
      </div>
    </div>
  </AppDrawer>
</template>

<style lang="scss" scoped>
.main-notice {
  min-width: 200px;
  > h4 {
    color: $text-primary;
    @include h-2;
  }

  > p {
    margin-top: 16px;
    color: $text-third;
    @include m-text;
  }

  &__footer {
    margin-top: 24px;
    height: 56px;
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-start;
  }
}
</style>
