<script setup>
import { useStore } from "vuex";
import PageLoader from "@/components/Layouts/MainLayout/PageLoader.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";

const store = useStore();

const route = useRoute();

const itsCalendar = computed(() => route.name === "CalendarView");
const itsNotification = computed(() => route.name === "ProfileNotifications");
const itsMobileProfile = computed(() => route.name === "ProfileView");

const itsChannelEdit = computed(() =>
  ["CreateChannelView", "CreatePrivateChannelView", "EditChannelView"].includes(
    route.name
  )
);
const itsMessenger = computed(() =>
  ["ChatChannelsView", "ChatCurrentChannelView"].includes(route.name)
);
</script>

<template>
  <main
    class="main-view"
    :class="{
      'floating-action': store.state.system.isMobileFloatingActionVisible,
      'background-gray': store.state.system.grayBackground,
      'no-gaps': store.state.system.isOnlyMobilePage,
      'top-gaps': store.state.system.isOnlyTopGap,
      clean: store.state.system.noNavigationBar,
      'its-calendar': itsCalendar,
      'its-notification': itsNotification,
      'its-profile': itsMobileProfile || (itsChannelEdit && !itsChannelEdit),
      'its-chat': itsMessenger,
      'its-channel-edit': itsChannelEdit,
    }"
  >
    <PageLoader v-if="store.state.system.isPageLoading" is-loading />
    <RouterView />
  </main>
</template>

<style scoped lang="scss">
.main-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: $base-space * 30;

  &.its-profile {
    margin-top: 0;
    padding: 0 0 66px;
  }

  &.its-notification {
    margin-top: 0;
    padding: 72px 0 0;
  }

  &.its-chat {
    margin-top: 0;
    padding: 0;
  }

  &.its-channel-edit {
    margin-top: 0;
    padding: 96px 0 0 0;

    @media (max-width: 1023px) {
      padding: 0;
    }
  }

  &.its-calendar {
    @media (max-width: 968px) {
      padding-bottom: $base-space * 10;
    }

    @media (max-width: 767px) {
      padding-bottom: $base-space * 100;
    }

    @media (max-width: 590px) {
      padding-bottom: $base-space * 100;
    }

    @media (max-width: 430px) {
      padding-bottom: $base-space * 120;
    }

    @media (width: 540px) and (height: 720px) {
      padding-bottom: $base-space * 80;
    }

    @media (width: 280px) and (height: 653px) {
      padding-bottom: $base-space * 120;
    }
  }

  @media (max-width: 1023px) {
    margin-top: 68px;
  }

  &.floating-action {
    padding-bottom: $base-space * 30 + 72px;
  }

  &.no-gaps {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    @include _md {
      margin-top: 0 !important;
    }
  }

  &.top-gaps {
    padding-bottom: 0 !important;
  }

  &.clean {
    padding: 0 !important;
    margin: 0 !important;
    flex: 1;
  }

  @include _md {
    margin-top: 90px;
    padding-top: $base-space * 18;
    padding-bottom: $base-space * 30;
  }

  @include _lg {
    padding-top: $base-space * 24;
  }
}
</style>
