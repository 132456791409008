<script setup>
import ContentBox from "@/components/Ui/ContentBox.vue";
import AppInput from "@/components/Ui/AppInput.vue";
import { onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { useDebounceFn } from "@vueuse/core";

const store = useStore();

const isCustomInputEnable = ref(false);

const months = [
  {
    title: "1 мес.",
    value: 1,
  },
  {
    title: "3 мес.",
    value: 3,
  },
  {
    title: "6 мес.",
    value: 6,
  },
  {
    title: "1 год.",
    value: 12,
  },
];

const update = (value) => {
  store.commit("customTariff/SET_MONTHS", value);
};

const recalculate = useDebounceFn(() => {
  store.dispatch("customTariff/calculate");
}, 300);

const handleToggleCustomInput = (status) => {
  isCustomInputEnable.value = status;
};

const handleCustomInputChange = (value) => {
  update(Number(value));
  recalculate();
};

const handleSet = (value) => {
  if (isCustomInputEnable.value) isCustomInputEnable.value = false;
  update(value);
  recalculate();
};

onBeforeMount(() => {
  if (!months.find((m) => m.value === store.state.customTariff.months))
    isCustomInputEnable.value = true;
});
</script>

<template>
  <ContentBox
    title="Период действия"
    description="Выберите временной период, в течение которого будет действовать тариф"
  >
    <div class="selector">
      <button
        v-for="month in months"
        :key="month.value"
        type="button"
        class="button selector-option"
        :class="{
          button_secondary:
            store.state.customTariff.months !== month.value ||
            isCustomInputEnable,
        }"
        @click="handleSet(month.value)"
      >
        {{ month.title }}
      </button>
      <button
        type="button"
        class="button selector-option"
        :class="{ button_secondary: !isCustomInputEnable }"
        @click="handleToggleCustomInput(true)"
      >
        Другой период
      </button>
    </div>
    <div v-if="isCustomInputEnable" class="custom-input">
      <AppInput
        label="Период действия"
        :model-value="store.state.customTariff.months"
        mask="###"
        @update:modelValue="handleCustomInputChange"
      >
        <template #right> мес </template>
      </AppInput>
    </div>
  </ContentBox>
</template>

<style scoped lang="scss">
.selector {
  display: flex;
  gap: $base-space * 4;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  .selector-option {
    white-space: nowrap;
  }
}

.custom-input {
  margin-top: $base-space * 6;
}
</style>
