import moment from "moment/moment";

export const getTextDateNotification = (date) => {
  const currentDate = moment().format("YYYY-MM-DD"); // Текущий день
  const newDate = moment(date).format("YYYY-MM-DD"); // Дата сообщения
  const startWeekIso = moment().isoWeekday(1);
  const startWeek = moment(startWeekIso).format("YYYY-MM-DD"); // Начало недели
  const endWeekIso = moment().isoWeekday(7);
  const endWeek = moment(endWeekIso).format("YYYY-MM-DD"); // Конец недели
  if (moment(newDate).isSame(currentDate)) return "Сегодня";
  if (moment(newDate).isBetween(startWeek, endWeek, null, []))
    return "На этой неделе";

  return "Ранее";
};
