import HttpClient from "@/api";

const state = {
  companies: [],
  perPage: 15,
  page: 1,
  lastPage: 1,
  total: 0,
  myCompany: null,
  isAddingCompany: false,
  isLoading: false,
  isLoadMoreLoading: false,
  isPageLoaded: false,
  isCompanyNotFoundError: false,
};

const mutations = {
  SET_COMPANIES(store, payload) {
    store.companies = payload;
  },
  RESET_COMPANIES(store) {
    store.companies = [];
    store.page = 1;
    store.lastPage = 1;
    store.total = 0;
    store.isLoading = false;
    store.isLoadMoreLoading = false;
    store.isPageLoaded = false;
  },

  UPDATE_COMPANIES(store, payload) {
    store.companies = [
      ...(payload.loadMore ? store.companies : []),
      ...payload.companies,
    ];
    store.lastPage = payload.meta.last_page;
    store.page = payload.meta.current_page;
    store.total = payload.meta.total;
    store.myCompany = payload.myCompany;
  },

  TOGGLE_LOADING(store, payload) {
    store.isLoading = payload ?? !store.isLoading;
  },

  TOGGLE_LOAD_MORE_LOADING(store, payload) {
    store.isLoadMoreLoading = payload ?? !store.isLoadMoreLoading;
  },

  TOGGLE_PAGE_LOADING(store) {
    store.isPageLoaded = true;
  },

  SET_ADDING_COMPANY(store, payload) {
    store.isAddingCompany = payload;
  },

  RESET_COMPANY_NOT_FOUND_ERROR(store) {
    store.isCompanyNotFoundError = false;
  },

  TOGGLE_COMPANY_NOT_FOUND_ERROR(store, payload) {
    store.isCompanyNotFoundError = payload ?? !store.isCompanyNotFoundError;
  },
};

const actions = {
  async loadCompanies(store, payload) {
    const loadingAction = payload?.loadMore
      ? "TOGGLE_LOAD_MORE_LOADING"
      : "TOGGLE_LOADING";
    try {
      store.commit(loadingAction, true);
      const params = {
        page: payload.loadMore ? store.state.page + 1 : 1,
        per_page: store.state.perPage,
      };

      if (payload.searchQuery) params.query = payload.searchQuery;

      const response = await HttpClient.get(`api/companies`, {
        params,
      });

      let myCompanyResponse = null;

      try {
        myCompanyResponse = await HttpClient.get(`api/companies/my`, {
          params,
        });
      } catch (error) {
        console.error(error);
      }

      if (!response.data) throw new Error("No data");
      store.commit("UPDATE_COMPANIES", {
        companies: response.data.data,
        meta: response.data?.meta,
        loadMore: payload.loadMore,
        myCompany: myCompanyResponse?.data?.data ?? null,
      });
    } catch (error) {
      console.error(error);
    } finally {
      store.commit(loadingAction, false);
      store.commit("TOGGLE_PAGE_LOADING");
    }
  },
  async addCompany({ commit }) {
    try {
      commit("SET_ADDING_COMPANY", true);
      const response = await HttpClient.post(`api/companies/add`);
      commit("TOGGLE_HAVE_COMPANY", true);
      return Promise.resolve(response.data?.data?.id);
    } catch {
      commit("TOGGLE_COMPANY_NOT_FOUND_ERROR", true);
    } finally {
      commit("SET_ADDING_COMPANY", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
