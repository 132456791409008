import HttpClient from "@/api";
import responseErrorMessageHandler from "@/utils/responseErrorMessageHandler";
import { isValidJSON } from "@/package/helpers/validation";
import { getFilesFromPhotos } from "@/utils/files";

const state = {
  community: null,
  isLoading: false,
  isSubscribing: false,
  memberList: [],
  perPage: 15,
  current_page: 1,
  lastPage: 0,
  total: 0,
  communityEvent: null,

  communityPosts: [],
  currentPost: null,
};

const mutations = {
  RESET_COMMUNITY(store) {
    store.community = null;
    store.isLoading = false;
  },

  RESET_MEMBER_LIST(store) {
    store.memberList = [];
    store.total = 0;
  },

  RESET_RECORD(store) {
    store.communityPosts = [];
  },

  UPDATE_COMMUNITY(store, payload) {
    const _community = Object.assign({}, payload.community);
    if (
      !Array.isArray(_community.social_networks) &&
      isValidJSON(_community.social_networks)
    ) {
      _community.social_networks = JSON.parse(_community.social_networks);
    }
    store.community = _community;
  },

  TOGGLE_LOADING(store, payload) {
    store.isLoading = payload ?? !store.isLoading;
  },

  TOGGLE_SUBSCRIBING(store, payload) {
    store.isSubscribing = payload ?? !store.isSubscribing;
  },

  SET_COMMUNITY_POSTS(store, payload) {
    store.communityPosts = payload;
  },

  SET_CURRENT_POST(store, payload) {
    store.currentPost = payload;
  },

  SET_PER_PAGE(store, payload) {
    store.current_page = payload;
  },

  GET_MEMBERS_LIST(store, { members, infinityScrolled }) {
    if (infinityScrolled) {
      store.memberList = [...store.memberList, ...members];
    } else {
      store.memberList = members;
    }
  },

  GET_MEMBERS(store, payload) {
    store.total = payload;
  },

  GET_PAGES(store, payload) {
    store.lastPage = payload;
  },

  SET_COMMUNITY_EVENT(store, payload) {
    store.communityEvent = payload;
  },
};

const actions = {
  async loadCommunity(store, payload) {
    try {
      store.commit("TOGGLE_LOADING", true);
      const response = await HttpClient.get(`api/communities/${payload.id}`);
      if (!response.data) throw new Error("No data");
      store.commit("UPDATE_COMMUNITY", {
        community: response.data.data,
      });
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING", false);
    }
  },

  async createPrivateChannel(store, payload) {
    try {
      const { idCommunity, ...other } = payload;

      await HttpClient.postForm(`api/communities/${idCommunity}/chat`, other);
    } catch (error) {
      return Promise.reject(new Error("Не удалось создать приватный канал"));
    }
  },

  async loadMembersList(store, payload) {
    const params = {
      page: store.state.current_page,
    };

    if (payload.infinityScrolled && params.page < store.state.lastPage) {
      params.page++;
      store.commit("SET_PER_PAGE", params.page);
    } else if (payload.infinityScrolled) {
      return;
    }

    const response = await HttpClient.get(
      `api/communities/${payload.id}/users`,
      {
        params,
      }
    );

    store.commit("GET_MEMBERS_LIST", {
      members: response.data.data.map((item) => {
        return {
          id: item.id,
          first_name: item.first_name,
          last_name: item.last_name,
          media: item.media.map((j) => j.url),
        };
      }),
      infinityScrolled: payload.infinityScrolled,
    });
    store.commit("GET_PAGES", response.data.meta.last_page);

    store.commit("GET_MEMBERS", response.data.meta.total);
  },

  async checkCreator(store, payload) {
    try {
      const response = await HttpClient.get(
        `api/communities/${payload.communityId}`
      );

      return response?.data?.data?.creator_id === payload.creatorId;
    } catch (error) {
      return false;
    }
  },

  async subscribe(store) {
    try {
      store.commit("TOGGLE_SUBSCRIBING", true);

      const response = await HttpClient.post(
        `api/communities/${store.state.community.id}/join`
      );
      if (!response.data) throw new Error("No data");

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(
        responseErrorMessageHandler(error, "Не удалось загрузить сообщество")
      );
    } finally {
      store.commit("TOGGLE_SUBSCRIBING", false);
    }
  },

  async unsubscribe(store) {
    try {
      store.commit("TOGGLE_SUBSCRIBING", true);

      const response = await HttpClient.post(
        `api/communities/${store.state.community.id}/leave`
      );
      if (!response.data) throw new Error("No data");

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(
        responseErrorMessageHandler(
          error,
          "Не удалось отписаться от сообщества"
        )
      );
    } finally {
      store.commit("TOGGLE_SUBSCRIBING", false);
    }
  },

  setMediaPost(_, payload) {
    const params = {
      file: payload.file,
    };
    return new Promise((resolve, reject) => {
      HttpClient.postForm(
        `api/communities/${payload.community}/posts/${payload.post}/media/add`,
        params
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteMediaPost(_, payload) {
    const params = {
      media_id: payload.media_id,
    };
    return new Promise((resolve, reject) => {
      HttpClient.post(
        `api/communities/${payload.community}/posts/${payload.post}/media/delete`,
        params
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCommunityPosts(store, community_id) {
    return new Promise((resolve, reject) => {
      HttpClient.get(`api/communities/${community_id}/posts`)
        .then((response) => {
          store.commit("SET_COMMUNITY_POSTS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setCommunityPost(store, payload) {
    const params = {
      text: payload.text,
      // links: [payload.link],
      files: getFilesFromPhotos(payload.photos),
    };

    if (payload.link.trim() !== "") {
      params.links = [payload.link];
    }

    return new Promise((resolve, reject) => {
      HttpClient.postForm(
        `api/communities/${payload.community_id}/posts`,
        params
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendNotificationMembers(store, payload) {
    const params = {
      title: payload.title,
      body: payload.body,
      if_html: false,
    };

    return new Promise((resolve, reject) => {
      HttpClient.postForm(
        `api/communities/${payload.community_id}/users/notification`,
        params
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          responseErrorMessageHandler(
            error,
            "Не удалось отправить уведмоление"
          );
          reject(error);
        });
    });
  },

  getCurrentPost(store, payload) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `api/communities/${payload.community_id}/posts/${payload.id}`
      )
        .then((response) => {
          store.commit("SET_CURRENT_POST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changePost(store, payload) {
    const params = {
      text: payload.text,
      links: payload.links,
    };

    const deleteMediaNormalize = [];
    const addMediaNormalize = [];

    payload.deleteMedia
      .map((i) => i.id)
      .forEach((i) => {
        deleteMediaNormalize.push(
          store.dispatch("deleteMediaPost", {
            media_id: i.toString(),
            community: payload.community_id,
            post: payload.id,
          })
        );
      });

    getFilesFromPhotos(payload.addMedia).forEach((i) => {
      addMediaNormalize.push(
        store.dispatch("setMediaPost", {
          file: i,
          community: payload.community_id,
          post: payload.id,
        })
      );
    });

    return new Promise((resolve, reject) => {
      HttpClient.patch(
        `api/communities/${payload.community_id}/posts/${payload.id}`,
        params
      )
        .then((response) => {
          Promise.all([...deleteMediaNormalize, ...addMediaNormalize]).then(
            () => {
              resolve(response);
            }
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deletePost(_, payload) {
    return new Promise((resolve, reject) => {
      HttpClient.delete(
        `api/communities/${payload.community_id}/posts/${payload.id}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteCommunityMedia(_, payload) {
    return new Promise((resolve, reject) => {
      HttpClient.delete(
        `api/communities/${payload.community_id}/events/${payload.event_id}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCommunityEvent(store, payload) {
    return new Promise((resolve, reject) => {
      HttpClient.get(`api/events/${payload.event_id}`)
        .then((response) => {
          store.commit("SET_COMMUNITY_EVENT", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
