<script setup>
import { computed, defineEmits, defineProps } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";
import { useStore } from "vuex";

defineOptions({
  name: "NotificationCard",
});

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },

  notificationItem: {
    type: Object,
    required: false,
    default: null,
  },
});

const { push } = useRouter();
const store = useStore();

const emit = defineEmits(["closeDropdownHandler"]);

const closeDropdownHandler = () => {
  emit("closeDropdownHandler");
};

const currentCommunity = computed(() => {
  if (props.notificationItem?.data.type === "system") {
    return "Уведомление от администратора";
  } else if (props.notificationItem?.data.type === "event") {
    return "Уведомление о мероприятии";
  } else if (
    props.notificationItem?.data.type === "calendar_event" &&
    props.notificationItem?.data.event_type === "personal"
  ) {
    return "Личное напоминание";
  } else if (
    props.notificationItem?.data.type === "calendar_event" &&
    props.notificationItem?.data.event_type === "public"
  ) {
    return "Уведомление о мероприятии";
  } else if (props.notificationItem?.data.type === "community") {
    return "Уведомление из сообщества";
  } else if (props.notificationItem?.data.type === "direct") {
    return "Уведомление от сообщества";
  } else if (props.notificationItem?.data.type === "community_chat_join") {
    return "Уведомление от сообщества";
  } else return "";
});

const itsCalendarEvent = computed(() => {
  return props.notificationItem?.data.type === "calendar_event";
});

const itsCommunity = computed(() => {
  return props.notificationItem?.data.type === "community";
});

const itsSystem = computed(() => {
  return props.notificationItem?.data.type === "system";
});

const itsEventFromPanel = computed(() => {
  return props.notificationItem?.data.type === "event";
});

const dateCalendarEvent = computed(() => {
  if (props.notificationItem?.data.type === "calendar_event") {
    return moment(props.notificationItem?.data?.date_at).format("YYYY-MM-DD");
  } else return "";
});

const openNotification = () => {
  setTimeout(() => {
    closeDropdownHandler();
  }, 200);

  store.commit("notifications/READ_NOTIFICATION");

  if (props.notificationItem?.data.event_type === "personal") {
    store.dispatch("notifications/loadNotificationById", {
      id: props.notificationItem?.id,
    });
  }

  if (
    props.notificationItem?.data.type === "calendar_event" &&
    props.notificationItem?.data.event_type === "personal"
  ) {
    return push({
      name: "CalendarPageView",
      params: { id: props.notificationItem?.data?.event_id },
      query: { date: dateCalendarEvent.value },
    });
  } else
    return push({
      name: "ProfileNotification",
      params: { id: props.notificationItem.id },
    });
};
</script>

<template>
  <button class="notification-card" @click="openNotification">
    <span
      class="notification-card__title title"
      :class="{
        title_unread: props.notificationItem?.read_at === null,
      }"
    >
      <span
        v-if="props.notificationItem?.read_at === null"
        class="title__unread unread"
      >
        <i class="unread__circle" />
      </span>
      <span class="title__label">
        {{ notificationItem.data.title }}
      </span>
    </span>
    <span class="notification-card__type-notification">
      {{ currentCommunity }}
    </span>
    <span v-if="itsCalendarEvent" class="notification-card__body">
      {{ notificationItem.data.text }}
    </span>
    <span v-else-if="itsCommunity" class="notification-card__body">
      {{ notificationItem.data.body[0] }}
    </span>
    <span
      v-else-if="itsSystem || itsEventFromPanel"
      class="notification-card__body"
      v-html="notificationItem.data.body"
    />
    <span v-else class="notification-card__body">
      {{ notificationItem.data.body }}
    </span>
  </button>
</template>

<style lang="scss" scoped>
.notification-card {
  display: grid;
  width: 100%;
  grid-template-rows: repeat(3, auto);
  gap: 8px;
  padding: 16px 20px;
  justify-content: left;
  cursor: pointer;

  &:hover {
    background: $background-light-accent;
  }

  .title {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    &_unread {
      display: flex;
      grid-template-columns: 8px 1fr !important;
      gap: 8px;
    }

    .unread {
      display: flex;
      width: 8px;
      height: 22px;
      align-items: center;

      &__circle {
        display: flex;
        width: 8px;
        height: 8px;
        background: $background-accent;
        border-radius: 50%;
      }
    }

    &__label {
      @include m-text-bold;
      display: flex;
      color: $text-primary;
      word-break: break-word;
      white-space: break-spaces;
      text-align: left;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 44px;
    }
  }

  &__type-notification {
    @include m-text-bold;
    color: $text-second;
    text-align: left;
  }

  &__body {
    @include s-text;
    color: $text-third;
    text-align: left;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 44px;
  }
}
</style>
