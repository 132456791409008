<script setup>
import { useStore } from "vuex";
import UiBottomSheet from "@/components/Ui/UiBottomSheet.vue";
import { computed, ref, watch } from "vue";

const store = useStore();

const window_width = computed(() => store.state.system.window_width);

const user = computed(() => store.state.user.user);

const unreadMessage = computed(() => store.state.notifications.unreadMessages);

const items = computed(() => {
  if (showBottomSheet.value) {
    return [];
  } else {
    return [
      { link: "CalendarView", title: "Календарь", icon: "calendar" },

      {
        link: "NewsView",
        title: "Новости",
        icon: "news",
      },
      { link: "ProfileView", title: "Профиль", icon: "person" },
      { link: "SosView", title: "Красная кнопка", icon: "sos" },
    ];
  }
});

const authorCourses = computed(() => {
  if (user.value.tariff.code === "member") {
    return null;
  }
  return {
    link: "AuthorCoursesView",
    title: "Авторские курсы",
    icon: "speaker",
  };
});

const investProgram = computed(() => {
  if (user.value.tariff.code === "member") {
    return null;
  }
  return {
    link: "InvestmentProgramView",
    title: "Партнерство",
    icon: "cashback",
  };
});

// foreignCompany() {
//   if (this.user.tariff.code === "member") {
//     return null;
//   }
//   return {
//     link: "ForeignCompanyView",
//     title: "Иностранные партнеры",
//   };
// },

const navigation = computed(() => {
  return [
    { link: "CalendarView", title: "Календарь", icon: "calendar" },
    {
      link: "NewsView",
      title: "Новости",
      icon: "news",
    },
    { link: "ProfileView", title: "Профиль", icon: "person" },
    { link: "SosView", title: "Красная кнопка", icon: "sos" },
    { link: "EventsView", title: "Мероприятия", icon: "promocode" },

    { link: "CommunitiesView", title: "Сообщества", icon: "people" },
    {
      link: "SupportCentersView",
      title: "Центр поддержки",
      icon: "comments",
    },
    investProgram.value,
    // this.foreignCompany,
    { link: "ServicesView", title: "Полезные сервисы", icon: "development" },
    {
      link: "CompaniesView",
      title: "Компании",
      icon: "business",
    },
    { link: "CoursesView", title: "Бизнес университет", icon: "graduate" },
    authorCourses.value,
    {
      link: "ChatChannelsView",
      title: "Мессенджер",
      icon: "messenger",
    },
  ];
});

const filterNavigation = computed(() => {
  return navigation.value.filter((item) => item !== null);
});

const showBottomSheet = ref(false);
const hideOptions = ref(true);

watch(
  () => showBottomSheet.value,
  (newValue) => {
    if (newValue) {
      const body = document.querySelector("body");
      body.style.overflow = "hidden";
    } else {
      const body = document.querySelector("body");
      body.style.removeProperty("overflow");
    }
  }
);

const hideOptionsHandler = (value) => {
  hideOptions.value = value;
};

const getShowModal = () => {
  hideOptions.value = !hideOptions.value;

  setTimeout(() => {
    showBottomSheet.value = !showBottomSheet.value;
  }, 100);
};
</script>

<template>
  <nav
    v-if="
      store.state.system.window_width < 1024 &&
      !store.state.system.noNavigationBar
    "
    class="nav-bar"
  >
    <div class="container mobile-paddings">
      <ul>
        <li v-for="item in items" :key="item.link">
          <RouterLink
            :to="{ name: item.link }"
            :class="{ 'sos-button': item.icon === 'sos' }"
          >
            <i :class="`icon-${item.icon}`" />
          </RouterLink>
        </li>
        <button @click="getShowModal">
          <i class="icon-service" />
        </button>
      </ul>
    </div>
    <UiBottomSheet
      v-if="showBottomSheet && window_width < 1025"
      v-model="showBottomSheet"
      :hide-options="hideOptions"
      @hideOptions="hideOptionsHandler"
    >
      <div class="bottom-sheet-service">
        <h1>Меню</h1>
        <div class="bottom-sheet-service__actions">
          <button
            v-for="item in filterNavigation"
            :key="item.link"
            @click="getShowModal"
          >
            <RouterLink
              :id="item.icon === 'sos' ? 'sos-button' : ''"
              :to="{ name: item.link }"
            >
              <span
                v-if="unreadMessage > 0 && item.link === 'ChatChannelsView'"
              >
                {{ unreadMessage > 99 ? unreadMessage + "+" : unreadMessage }}
              </span>
              <i :class="`icon-${item.icon}`" />
            </RouterLink>
            <span>{{ item.title }}</span>
          </button>
        </div>
      </div>
    </UiBottomSheet>
  </nav>
</template>

<style scoped lang="scss">
.nav-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(12px);
  padding: ($base-space * 2) ($base-space * 2)
    calc(8px + env(safe-area-inset-bottom)) ($base-space * 2);

  @media (display-mode: standalone) {
    padding-bottom: env(safe-area-inset-bottom);
  }

  > .container {
    > ul {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      > li {
        > a {
          height: 50px;
          width: 59px;
          background: none;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          padding: 4px;
          text-align: center;
          padding: ($base-space * 2);
          flex-flow: column;
          gap: calc($base-space / 2);
          color: $text-fourth;
          @include xxs-text-bold();

          &.router-link-active {
            background: $background-accent;

            > i {
              background: $text-light;
              width: 24px;
              height: 24px;
            }
          }

          > i {
            background: $text-primary;
            width: 24px;
            height: 24px;
          }
        }
      }

      > button {
        height: 50px;
        width: 59px;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        padding: 4px;
        text-align: center;
        padding: ($base-space * 2);
        flex-flow: column;
        gap: calc($base-space / 2);
        color: $text-fourth;
        @include xxs-text-bold();

        > span {
          @include xxs-text-bold;
          display: inline-block;
          position: absolute;
          z-index: 99;
          min-width: 14px;
          height: 14px;
          right: 0;
          padding: 0 4px;
          border-radius: 16px;
          color: $text-light;
          border: 1.5px solid $text-light;
          background: $text-error;
        }

        > i {
          background: $text-primary;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.sos-button {
  > i {
    background-color: $text-error !important;
  }

  &.router-link-active {
    > i {
      background: $text-light !important;
    }
  }
}
</style>
