import moment from "moment";

export default function groupingMessages(messages) {
  return messages.reduce((acc, item) => {
    const lenAcc = acc.length;
    const date = moment(item.created_at).format("DD-MM-YYYY");
    let groupDate = null;

    if (lenAcc) groupDate = moment(acc[lenAcc - 1].date).format("DD-MM-YYYY");

    if (lenAcc && groupDate === date) {
      acc[lenAcc - 1].items.push({ ...item });
    } else {
      acc.push({ date: item.created_at, items: [{ ...item }] });
    }

    return acc;
  }, []);
}
