<script>
import AppModal from "@/components/Ui/AppModal.vue";
import { mapState } from "vuex";

export default {
  name: "TrialPeriodNotification",
  components: { AppModal },
  emits: ["close"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),

    isOpenNotification() {
      return this.isOpen && !this.user.tariff_trial_is_used;
    },
  },

  methods: {
    handleClose() {
      this.$emit("close");
    },

    goStatusRdo() {
      this.$router.push({
        name: "ProfileTariffs",
      });
      this.handleClose();
    },
  },
};
</script>

<template>
  <AppModal v-if="isOpenNotification" @close="handleClose">
    <div class="trial-period-notification">
      <img src="../assets/images/trial-tariff.svg" alt="trial-tariff" />

      <h2>Пробный период</h2>

      <p>
        Оформите подписку «Резидент РДО» или «Кандидат в мастера РДО» на 7 дней
        всего за 1 рубль
      </p>

      <button class="button" type="button" @click="goStatusRdo">
        Оформить подписку
      </button>
    </div>
  </AppModal>
</template>

<style lang="scss" scoped>
.trial-period-notification {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    width: 72px;
    height: 72px;
    margin-bottom: 24px;
  }

  > h2 {
    color: $text-primary;
    @include h-2;
    margin-bottom: 8px;
  }

  > p {
    color: $text-second;
    @include s-text;
    margin-bottom: 32px;
    text-align: center;
  }
}
</style>
