<script>
import { mapActions, mapMutations, mapState } from "vuex";
import TrialPeriodNotification from "@/components/TrialPeriodNotification.vue";

export default {
  name: "App",
  components: { TrialPeriodNotification },

  computed: {
    ...mapState({
      notification: (state) => state.system.notification,
      isTrialPeriodTariffOpen: (state) => state.system.isTrialPeriodTariffOpen,
    }),
  },

  methods: {
    ...mapActions({
      loadRegions: "system/loadRegions",
      shutdown: "notifications/shutdown",
    }),

    ...mapMutations({
      setScrollHeight: "system/SET_SCROLL_HEIGHT",
      setWindowWidth: "system/SET_WINDOW_WIDTH",
      setTrialNotice: "system/SET_TRIAL_TARIFF_NOTICE",
    }),

    closeTrialPeriodNotification() {
      this.setTrialNotice(false);
    },
  },

  created() {
    window.addEventListener("scroll", this.setScrollHeight);
    this.setScrollHeight();
    window.addEventListener("resize", this.setWindowWidth);
    this.setWindowWidth();
  },

  mounted() {
    this.loadRegions();
  },

  unmounted() {
    window.removeEventListener("scroll", this.setScrollHeight);
    window.removeEventListener("resize", this.setWindowWidth);
    this.shutdown();
  },
};
</script>

<template>
  <RouterView />

  <Transition>
    <div
      v-if="notification"
      class="notification"
      :class="{ notification_error: notification.type === 'error' }"
    >
      <i
        :class="`icon-${notification.type === 'error' ? 'warning' : 'check'}`"
      />
      <p>{{ notification.message }}</p>
    </div>
  </Transition>
  <!--:is-open = "isTrialPeriodTariffOpen" -->
  <TrialPeriodNotification
    :is-open="false"
    @close="closeTrialPeriodNotification"
  />
</template>

<style scoped lang="scss">
.notification {
  padding: ($base-space * 4) ($base-space * 4);
  @include main-shadow();
  border-radius: 12px;
  position: fixed;
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  background-color: $background-accent;
  grid-gap: $base-space * 2;
  width: 400px;
  left: calc(50% - 200px);
  bottom: $base-space * 8;
  z-index: 100;

  @media (max-width: 1023px) {
    bottom: 78px;
  }

  @media (max-width: 767px) {
    width: calc(100% - 16px);
    left: 8px;
  }

  > i {
    width: 24px;
    height: 24px;
    background-color: $text-light;
  }

  > p {
    @include m-text();
    color: $text-light;
  }

  &_error {
    background-color: $text-error;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease-in;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
