<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { useStore } from "vuex";
import AppInput from "@/components/Ui/AppInput.vue";
import { useRoute, useRouter } from "vue-router";

const props = defineProps({
  backUrl: {
    type: Object,
    default: undefined,
    required: false,
  },

  actions: {
    type: Array,
    required: false,
    default: () => [],
  },

  customAction: {
    type: Object,
    default: undefined,
    required: false,
  },

  title: {
    type: String,
    default: undefined,
    required: false,
  },

  edit: {
    type: Boolean,
    default: false,
    required: false,
  },

  textEdit: {
    type: Boolean,
    default: false,
    required: false,
  },

  shareActionTitle: {
    type: String,
    required: false,
    default: "Поделиться",
  },

  chatSearch: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["action", "editButton"]);

const store = useStore();

const { push } = useRouter();

const route = useRoute();

const currentRouteName = computed(() => route.name);

const handleToggleRegionsModal = () => {
  store.commit("system/CHANGE_REGIONS_MODAL_VIEW");
};

const handleToggleMobileSearch = () => {
  store.commit("system/TOGGLE_MOBILE_SEARCH");
};

const unreadNotification = computed(
  () => store.state.notifications.unreadNotification
);

const handleInputSearch = (e) => {
  store.commit("system/INPUT_SEARCH_QUERY", e.target.value);
};

const handleClose = () => {
  store.commit("system/TOGGLE_MOBILE_SEARCH", false);
  store.commit("system/INPUT_SEARCH_QUERY", "");
  store.commit("system/CHANGE_REGIONS_MODAL_VIEW", false);
};

const handleCustomAction = (e) => {
  emit("action", e);
};

const handleShare = () => {
  store.commit("system/TOGGLE_SHARE_MODAL", {
    title: props.shareActionTitle,
  });
};
const handleNotification = () => {
  push({
    name: "ProfileNotifications",
    query: { route: currentRouteName.value },
  });
};
</script>

<template>
  <Teleport to="#mobile-header">
    <div
      class="mobile-header mobile-paddings"
      :class="{ 'no-space': $slots.default }"
    >
      <template v-if="!$slots.default"
        ><template
          v-if="
            !store.state.system.isMobileSearchOpen &&
            !store.state.system.show_regions_modal
          "
        >
          <div class="left">
            <template v-if="backUrl">
              <RouterLink :to="backUrl" class="text-button">
                <i class="icon-arrow-left" /> Назад
              </RouterLink>
            </template>
            <button
              v-if="actions.includes('regions')"
              type="button"
              class="geolocation"
              @click="handleToggleRegionsModal"
            >
              <i class="icon-geolocation" />
              {{
                store.getters["user/getSelectedRegion"]
                  ? store.getters["user/getSelectedRegion"].name
                  : "Все регионы"
              }}
            </button>
          </div>
          <div v-if="title" class="title">{{ title }}</div>
          <div v-if="$slots.default" class="center-head">
            <slot />
          </div>
          <div class="right">
            <button
              v-if="edit"
              type="button"
              :class="[
                { 'action-button': !textEdit },
                { 'action-text': textEdit },
              ]"
              @click="$emit('editButton')"
            >
              <span v-if="textEdit" class="text-edit">Править</span>

              <i
                v-else
                class="icon-edit"
                style="background: rgba(0, 24, 165, 1)"
              />
            </button>
            <button
              v-if="actions.includes('share')"
              type="button"
              class="action-button"
              @click="handleShare"
            >
              <i class="icon-share" />
            </button>

            <button
              v-if="actions.includes('notifications')"
              type="button"
              class="action-button-notification"
              @click="handleNotification"
            >
              <span
                v-if="unreadNotification > 0"
                class="action-button-notification__online-circle"
                >{{
                  unreadNotification > 99
                    ? unreadNotification + "+"
                    : unreadNotification
                }}</span
              >
              <i class="icon-notification"></i>
            </button>

            <button
              v-if="actions.includes('search')"
              type="button"
              class="action-button"
              @click="handleToggleMobileSearch"
            >
              <i class="icon-search" />
            </button>
            <button
              v-if="customAction && !customAction.hide"
              type="button"
              class="custom-action"
              :class="{ 'is-important': customAction.isImportant }"
              @click="handleCustomAction"
            >
              {{ customAction.title }}
            </button>
          </div>
        </template>
        <template
          v-if="
            actions.includes('search') && store.state.system.isMobileSearchOpen
          "
        >
          <div class="mobile-search">
            <AppInput
              id="search"
              v-model="store.state.system.searchText"
              size="small"
              clear
              :chat-search="chatSearch"
              @input="handleInputSearch"
            >
              <template #left>
                <i class="icon-search" />
              </template>
            </AppInput>
          </div>
        </template>
        <button
          v-if="
            store.state.system.show_regions_modal ||
            store.state.system.isMobileSearchOpen
          "
          type="button"
          class="close"
          @click="handleClose"
        >
          Отмена
        </button>
      </template>
      <slot />
    </div>
  </Teleport>
</template>

<style scoped lang="scss">
.mobile-header {
  &.no-space {
    justify-content: flex-start;
  }
  .center-head {
    height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  display: flex;
  min-height: 68px;
  align-items: center;
  justify-content: space-between;

  .right {
    display: flex;
    align-items: center;
    gap: $base-space * 4;
  }

  .title {
    @include m-text-bold;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .action-button {
    width: 24px;
    height: 24px;

    > i {
      background: $text-primary;
      width: 24px;
      height: 24px;
    }
  }

  .action-button-notification {
    position: relative;

    &__online-circle {
      @include xxs-text-bold;
      display: inline-block;
      position: absolute;
      z-index: 1;
      min-width: 14px;
      height: 14px;
      //top: 2px;
      right: 0;
      padding: 0 4px;
      border-radius: 16px;
      color: $text-light;
      border: 1.5px solid $text-light;
      background: $text-error;
    }

    > i {
      width: 32px;
      height: 32px;
      background: $text-primary;
    }
  }

  .action-text {
    @include m-text();
    color: $text-accent;
  }

  .close,
  .custom-action {
    padding: 0;
    background: none;
    border: none;
    color: $text-accent;
    @include s-text-bold();
    margin-left: $base-space * 4;

    &.is-important {
      color: $text-error;
    }
  }

  .mobile-search {
    display: flex;
    flex: 1;
  }

  @include _md {
    display: none;
  }
}
</style>
